<template>
    <div class="add-new-travelling-order">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.invoiceCategoryUpdate')"
            :title="$t('messages.invoiceCategoryUpdate')"
            @submitPressed="submitPressed"
        />
        <invoice-category v-if="type" :typeObject="type" :action="action" @clearAction="action = null"/>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import InvoiceCategory from './components/InvoiceCategoryForm.vue'

export default {
    components: {
        TitleBar,
        InvoiceCategory,
    },
    data() {
        return {
            type: null,
            action: null,
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const id = this.$route.params.id;
            this.$InvoiceCategories.getResource({id}).then((response) => {
                this.type = response.data;
            });
        },
        submitPressed() {
            this.action = "update";
        },
    },
};
</script>
